.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.leftHome {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.textNote {
	color: #999;
	font-size: 15px;
	margin: 0;
}
.navbar {
    display: flex;
	background-color: #000;
	padding: 10px 0;
}
.navItem {
	list-style-type: none;
	width: 100%;
}
.navLink {
	color: #fff;
	text-decoration: none;
}
.pageContent {
	display: flex;
}
.leftHome, .rightHome {
	width: 50%;
}
.rightHome {
	background-color: #eee;
	padding:20px;
}